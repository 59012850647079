// extracted by mini-css-extract-plugin
export var pressCard__date = "cd_p6";
export var pressCard__description = "cd_p5";
export var pressCard__image_large = "cd_pY";
export var pressCard__image_small = "cd_pZ";
export var pressCard__info = "cd_p0";
export var pressCard__info_large = "cd_p1";
export var pressCard__info_small = "cd_p2";
export var pressCard__large = "cd_p7";
export var pressCard__link = "cd_pX";
export var pressCard__post = "cd_pW";
export var pressCard__small = "cd_pV";
export var pressCard__source = "cd_p3";
export var pressCard__title = "cd_p4";